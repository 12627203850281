import PropTypes from "prop-types"
import React from "react"
import { useTranslation } from "react-i18next"

import { Divider, Typography } from "@mui/material"
import styles from "./ArticleDetail.module.scss"
import NutriScore from "../../../nutri-score"
import useShop from "../../../../hooks/use-shop"
import ArticlelImage from "./ArticleImage"
import { ArticleDetailEntry } from "./ArticleDetailEntry"
import { mapBrandLayerName } from "../../../../util/brands"
import { EUR_SYMBOL, INFINITY_SYMBOL } from "../../../../util/constants"
import { KEY_INTERNAL_USER, getValue } from "../../../../util/local-storage"

const INTERNAL_USER = getValue(KEY_INTERNAL_USER)
const NOT_AVAILABLE = "not available"

function ArticleDetail({
  attributes,
  imageSources,
  shop,
  pricePerItem,
  pricePerUnit,
  multipackSizes,
  unsureMultipackSizes,
  unit,
  articleIds,
  baseProductId,
  turnover,
  rotation,
  year,
  sourceType,
  lastActiveOn,
}) {
  const { t } = useTranslation()
  const { logo: retailerLogo, name: retailerName } = useShop(shop)
  const formatValue = (value, type, title) => {
    if (!value) {
      return t(NOT_AVAILABLE)
    }
    if (title.toLowerCase() === "nutriscore") {
      return ""
    }

    if (type === "boolean") {
      return value.toLowerCase() === "true"
        ? t("Yes")
        : value.toLowerCase() === "false"
          ? t("No")
          : value
    }

    if (type === "array") {
      try {
        return JSON.parse(value)
          .map((v) => v.split("_").join(" "))
          .join(", ")
      } catch (_) {
        return value
      }
    }

    if (title?.toLowerCase() === "brand_layer") {
      return mapBrandLayerName(value).split("_").join(" ")
    }

    return value
  }

  let lastSeen = null
  if (lastActiveOn) {
    const date = new Date(lastActiveOn).toLocaleDateString()
    switch (sourceType) {
      case "scraping":
        lastSeen = t("Last seen online on {{date}}", { date })
        break
      case "fieldtake":
        lastSeen = t("Last captured in store on {{date}}", { date })
        break
      case "customer":
        lastSeen = t("Last seen in customer assortment on {{date}}", { date })
        break
      default:
        lastSeen = null
        break
    }
  }

  const main = [
    {
      title: t("Retailer"),
      value: retailerName,
      slot: (
        <img className={styles.logo} src={retailerLogo} alt={`${retailerName} logo`} />
      ),
      endNode: (
        <Typography variant="caption" color="textSecondary">
          {lastSeen}
        </Typography>
      ),
    },
    {
      title: t("Price"),
      value:
        pricePerItem === INFINITY_SYMBOL
          ? t(NOT_AVAILABLE)
          : `${EUR_SYMBOL}${pricePerItem}`,
    },
    {
      title: t("Price per {{unit}}", { unit: t(unit) }),
      value:
        pricePerUnit === INFINITY_SYMBOL
          ? t(NOT_AVAILABLE)
          : `${EUR_SYMBOL}${pricePerUnit}`,
    },
  ]

  const brands = attributes
    .filter((attribute) => {
      if (attribute.name === "brand" || attribute.name === "brand_layer") {
        return true
      }
      return false
    })
    .map(({ name: title, value, type, unsure, unvalidated }) => ({
      title: t(title?.split("_").join(" ")),
      slot: title.toLowerCase() === "nutriscore" && NutriScore({ value }),
      value: formatValue(value, type, title),
      unsure,
      unvalidated,
    }))

  const aside = attributes
    .map(({ name: title, value, type, unsure, unvalidated }) => ({
      title: t(title?.split("_").join(" ")),
      slot: title.toLowerCase() === "nutriscore" && NutriScore({ value }),
      value: formatValue(value, type, title),
      unsure,
      unvalidated,
    }))
    .filter((attribute) => {
      if (
        attribute.title === "brand" ||
        attribute.title === "brand layer" ||
        attribute.title === "image" ||
        attribute.title === "name"
      ) {
        return false
      }
      return true
    })

  aside.push({
    title: t("Multipack Sizes"),
    value: multipackSizes,
    unsure: unsureMultipackSizes,
  })

  aside.sort((a, b) => a.title.localeCompare(b.title))

  aside.push({ title: t("Turnover {{year}}", { year }), value: turnover })
  aside.push({ title: t("Evolution%  {{year}}", { year }), value: rotation })

  if (INTERNAL_USER) {
    aside.push({ title: t("Shop Code"), value: shop, debug: true })
    aside.push({
      title: t("Article Ids"),
      value: JSON.stringify(articleIds),
      debug: true,
    })
    aside.push({
      title: t("Base Product Id"),
      value: baseProductId,
      debug: true,
      link: `https://retool-dev.daltix.io/apps/1270fc62-a5aa-11ef-8114-b7867ae8b496/A3TOOL/Base%20Product%20Curation?baseProductIds=${baseProductId}`,
    })
    aside.push({
      title: t("Image Source"),
      value: JSON.stringify(imageSources),
      debug: true,
    })
  }

  return (
    <>
      <div className={styles.detail}>
        <div className={styles.mainContainer}>
          <div className={styles.main}>
            <div className={styles.image}>
              <ArticlelImage sources={imageSources} controls />
            </div>
          </div>
        </div>
        <div className={styles.aside}>
          <div className={styles.main}>
            {main.map(({ slot, title, value, endNode }) => (
              <ArticleDetailEntry
                key={`${title}-${value}`}
                slot={slot}
                title={title}
                value={value}
                endNode={endNode}
              />
            ))}
            <Divider sx={{ pb: 2 }} />
          </div>
          <div className={styles.data}>
            {brands.map(({ slot, title, value, debug, unsure, unvalidated, link }) => (
              <ArticleDetailEntry
                key={`${title}-${value}`}
                slot={slot}
                title={title}
                value={value}
                debug={debug || false}
                unsure={unsure}
                unvalidated={unvalidated}
                link={link}
              />
            ))}
            <Divider />
            {aside.map(({ slot, title, value, debug, unsure, unvalidated, link }) => (
              <ArticleDetailEntry
                key={`${title}-${value}`}
                slot={slot}
                title={title}
                value={value}
                debug={debug || false}
                unsure={unsure}
                unvalidated={unvalidated}
                link={link}
              />
            ))}
          </div>
        </div>
      </div>
    </>
  )
}

ArticleDetail.propTypes = {
  attributes: PropTypes.arrayOf(),
  imageSources: PropTypes.arrayOf(PropTypes.string),
  pricePerItem: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  pricePerUnit: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  multipackSizes: PropTypes.string,
  unsureMultipackSizes: PropTypes.bool,
  unit: PropTypes.string,
  shop: PropTypes.string.isRequired,
  articleIds: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.string),
    PropTypes.string,
  ]),
  baseProductId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  turnover: PropTypes.string,
  rotation: PropTypes.string,
  year: PropTypes.string,
  sourceType: PropTypes.string,
  lastActiveOn: PropTypes.string,
}

ArticleDetail.defaultProps = {
  attributes: [],
  imageSources: null,
  pricePerItem: INFINITY_SYMBOL,
  pricePerUnit: INFINITY_SYMBOL,
  multipackSizes: NOT_AVAILABLE,
  unsureMultipackSizes: false,
  unit: "Unit",
  articleIds: NOT_AVAILABLE,
  baseProductId: NOT_AVAILABLE,
  turnover: NOT_AVAILABLE,
  rotation: NOT_AVAILABLE,
  year: "",
  sourceType: NOT_AVAILABLE,
  lastActiveOn: null,
}

export { ArticleDetail }
