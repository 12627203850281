import React, { useCallback, useState } from "react"
import { Box, useTheme } from "@mui/material"

import styles from "./AssortmentAnalysisToolbar.module.scss"
import { AssortmentAnalysisControl } from "./AssortmentAnalysisControl"
import { AssortmentAnalysisProductSegmentAutocomplete } from "./AssortmentAnalysisProductSegmentAutocomplete"
import { AssortmentAnalysisDimensionSelector } from "./AssortmentAnalysisDimensionSelector"
import { AssortmentAnalysisFilters } from "./AssortmentAnalysisFilters"
import { AssortmentAnalysisModeButtons } from "./AssortmentAnalysisModeButtons"
import { AssortmentAnalysisSettings } from "./AssortmentAnalysisSettings"
import { AssortmentAnalysisTotal } from "./AssortmentAnalysisTotal"
import { AssortmentAnalysisFiltersReset } from "./AssortmentAnalysisFiltersReset"
import { AssortmentAnalysisNumeric } from "./AssortmentAnalysisNumeric"
import { useSetMode } from "./hooks"
import { DATA_MODE } from "../../ducks/pages/assortment-analysis/constants"
import { AssortmentAnalysisExport } from "./AssortmentAnalysisExport"

function AssortmentAnalysisToolbar() {
  const theme = useTheme()
  const { mode } = useSetMode()
  const [borderVisible, setBorderVisible] = useState({
    total: false,
  })

  const handleToggle = useCallback((control, show) => {
    setBorderVisible((prev) => ({
      ...prev,
      [control]: show,
    }))
  }, [])

  const handleTotalToggle = useCallback(
    (show) => {
      if (show === borderVisible.total) {
        return
      }
      handleToggle("total", show)
    },
    [borderVisible.total, handleToggle],
  )

  return (
    <Box
      className={styles["toolbar-root"]}
      data-testid="assortment-analysis-toolbar"
      sx={{
        p: 2,
      }}
    >
      <Box
        className={styles["control-list"]}
        sx={{
          border: "1px solid",
          borderColor: theme.palette.divider,
          justifyContent: "start",
          maxWidth: "100%",
          width: "fit-content",
        }}
      >
        <AssortmentAnalysisControl justify="start">
          <AssortmentAnalysisProductSegmentAutocomplete />
        </AssortmentAnalysisControl>
        <AssortmentAnalysisControl>
          <AssortmentAnalysisDimensionSelector />
        </AssortmentAnalysisControl>
        {mode === DATA_MODE && (
          <AssortmentAnalysisControl>
            <AssortmentAnalysisNumeric />
          </AssortmentAnalysisControl>
        )}
        <AssortmentAnalysisControl justify="end" border={false}>
          <AssortmentAnalysisModeButtons />
        </AssortmentAnalysisControl>
      </Box>
      <Box
        className={styles["control-list"]}
        sx={{ justifyContent: "start", overflowY: "auto", py: 1, width: "100%" }}
      >
        <AssortmentAnalysisControl>
          <AssortmentAnalysisFilters />
        </AssortmentAnalysisControl>
        <AssortmentAnalysisControl border={borderVisible.total}>
          <AssortmentAnalysisTotal />
        </AssortmentAnalysisControl>
        <AssortmentAnalysisControl border={false}>
          <AssortmentAnalysisFiltersReset onToggleControl={handleTotalToggle} />
        </AssortmentAnalysisControl>
        <AssortmentAnalysisControl grows justify="end" border={false}>
          <AssortmentAnalysisControl border={false}>
            <AssortmentAnalysisExport sx={{ mr: 1 }} />
            <AssortmentAnalysisSettings variant="text" />
          </AssortmentAnalysisControl>
        </AssortmentAnalysisControl>
      </Box>
    </Box>
  )
}

AssortmentAnalysisToolbar.propTypes = {}

AssortmentAnalysisToolbar.defaultProps = {}

export default AssortmentAnalysisToolbar
