import { get } from "lodash"
import { useDispatch, useSelector } from "react-redux"
import * as Actions from "../../../ducks/pages/assortment-analysis/action-creators"
import {
  ASSORTMENT_ANALYSIS_PAGE_KEY,
  ASSORTMENT_ANALYSIS_PAGE_EXPORT_KEY,
} from "../../../ducks/pages/assortment-analysis/constants"

/**
 * @returns {{ categoryExport: Object | undefined, exportCategory: (categoryId, productGroupId) => void }}
 *  the currently selected filters and a setter function
 */
export function useProductGroupExport() {
  const dispatch = useDispatch()

  const categoryExport = useSelector((state) =>
    get(state, [
      "pages",
      ASSORTMENT_ANALYSIS_PAGE_KEY,
      ASSORTMENT_ANALYSIS_PAGE_EXPORT_KEY,
    ]),
  )

  function exportCategory(categoryId, productGroupId) {
    dispatch(Actions.getCustomerAssortmentExport({ categoryId, productGroupId }))
  }
  return { categoryExport, exportCategory }
}
