import React from "react"
import mixpanel from "mixpanel-browser"
import { Breadcrumbs, Typography, Box, Button } from "@mui/material"
import { useTranslation } from "react-i18next"
import { useLocation } from "react-router-dom"
import { useProductGroupFilter, useProductGroupsOutline, useSetMode } from "./hooks"
import { Link } from "../ui"
import { imagesBaseURL } from "../../util/env"
import { assortmentToolRoutes } from "../../routes/assortment-tool/AssortmentTool"
import { assortmentToolRoutes as assortmentToolHomeRoutes } from "../../routes/assortment-tool/AssortmentToolHome"
import styles from "./AssortmentAnalysisBreadcrumbs.module.scss"
import { DATA_MODE } from "../../ducks/pages/assortment-analysis/constants"

function AssortmentAnalysisBreadcrumbs() {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const { data: productGroups, isIdle, isFetching, isError } = useProductGroupsOutline()
  const [productGroupId, setProductGroupId] = useProductGroupFilter()
  const { mode } = useSetMode()

  const disableHomeLink = pathname === assortmentToolHomeRoutes.categorySelector
  const showCategory = pathname.startsWith(assortmentToolRoutes.analysis)

  let categoryName = ""
  let groupName = ""
  if (showCategory && !isIdle && !isFetching && !isError) {
    const categoryId = Number(pathname.split("/").pop())
    const catGroup = productGroups.find((opt) => opt.categoryId === categoryId)
    categoryName = catGroup?.categoryName || ""
    const productGroup = productGroups.find((opt) => opt.id === productGroupId)
    groupName = productGroup?.groupName || ""
  }
  const showGroup = groupName && mode === DATA_MODE

  const renderText = (text, active = true) => (
    <Typography
      color={active ? "text.secondary" : "text.primary"}
      variant="h1"
      fontSize="24px"
      fontWeight="400"
    >
      {text}
    </Typography>
  )

  const renderHomeLink = () => {
    if (disableHomeLink) {
      return renderText(t("A³ Tool"), false)
    }

    return (
      <Link
        analyticsEventName="A3Tool - Breadcrumbs"
        analyticsEventPayload={{ breadcrumb: "Click on A3 Tool" }}
        color="text.secondary"
        to={assortmentToolHomeRoutes.categorySelector}
      >
        {renderText(t("A³ Tool"))}
      </Link>
    )
  }

  const renderCategoryLink = (text, isLink) => {
    if (!isLink) {
      return renderText(text, false)
    }
    const categoryId = Number(pathname.split("/").pop())
    return (
      <Button
        color="primary"
        variant="underlined"
        onClick={() => {
          mixpanel.track("A3Tool - Breadcrumbs", {
            breadcrumb: `Click on ${categoryName}`,
          })
          setProductGroupId({ id: categoryId }, true)
        }}
        sx={{ p: 0 }}
      >
        {renderText(text)}
      </Button>
    )
  }

  return (
    <Box
      sx={{
        alignItems: "center",
        display: "flex",
        flexDirection: "row",
        p: 2,
        width: "100%",
      }}
    >
      <img
        className={styles.icon}
        src={`${imagesBaseURL}/navigator/icons/cards/assortment-analysis.svg`}
        alt="assortment-analysis.svg"
        loading="lazy"
      />
      <Breadcrumbs aria-label="breadcrumb" sx={{ color: "text.secondary" }}>
        {renderHomeLink()}
        {showCategory && renderCategoryLink(t(categoryName), showGroup)}
        {showGroup && renderText(t(groupName), false)}
      </Breadcrumbs>
    </Box>
  )
}

AssortmentAnalysisBreadcrumbs.propTypes = {}

AssortmentAnalysisBreadcrumbs.defaultProps = {}

export { AssortmentAnalysisBreadcrumbs }
