import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { debounce as dbnc } from "lodash"
import Button from "@mui/material/Button"
import {
  Box,
  Divider,
  Icon,
  InputAdornment,
  Switch,
  TextField,
  Typography,
} from "@mui/material"
import { useStepFilter, useThresholdFilter, useSetHighlight } from "./hooks"
import { Slideover } from "../ui"
import {
  DEFAULT_HIGHLIGHT,
  DEFAULT_THRESHOLD,
  STEP_PRICE,
} from "../../ducks/pages/assortment-analysis/constants"
import { EUR_SYMBOL } from "../../util/constants"

const debounce = (func, wait = 500) => dbnc(func, wait)()

const Container = ({ children, ...rest }) => (
  <Box
    {...rest}
    sx={{
      alignItems: "center",
      display: "flex",
      justifyContent: "space-between",
      px: 2,
      py: 1,
      ...rest.sx,
    }}
  >
    {children}
  </Box>
)

Container.propTypes = { children: PropTypes.node }
Container.defaultProps = { children: null }

function AssortmentAnalysisSettings({ ...props }) {
  const { t } = useTranslation()
  const { highlight, savedHighlight, setHighlight } = useSetHighlight()
  const { step, savedStep, setStep } = useStepFilter()
  const { threshold, savedThreshold, setThreshold } = useThresholdFilter()
  const [open, isOpen] = useState(false)

  const [currHighlight, setHighlightState] = useState(savedHighlight)
  const [currStep, setStepState] = useState(savedStep)
  const [_currThreshold, setThresholdState] = useState(savedThreshold)

  useEffect(() => {
    if (highlight === DEFAULT_HIGHLIGHT && highlight !== savedHighlight) {
      setHighlight({ highlight: savedHighlight })
    }
    if (Number(step) === Number(STEP_PRICE) && Number(step) !== Number(savedStep)) {
      setStep({ step: savedStep })
    }
    if (
      Number(threshold) === Number(DEFAULT_THRESHOLD) &&
      Number(threshold) !== Number(savedThreshold)
    ) {
      setThreshold({ threshold: savedThreshold })
    }
  }, [
    highlight,
    savedHighlight,
    step,
    savedStep,
    threshold,
    savedThreshold,
    setHighlight,
    setStep,
    setThreshold,
  ])

  const handleOpen = () => {
    isOpen(true)
  }

  const handleClose = () => {
    isOpen(false)
  }

  const handleStepChange = ({ target }) => {
    setStepState(target.value)
    debounce(() => setStep({ step: target.value }), 600)
  }

  const handleHighlight = () => {
    setHighlightState(() => !currHighlight)
    setHighlight({ highlight: !currHighlight })
  }

  const _handleThresholdChange = ({ target }) => {
    setThresholdState(target.value)
    debounce(() => setThreshold({ threshold: target.value }), 600)
  }

  return (
    <>
      <Box display="flex" gap={3}>
        <Button
          {...props}
          size="small"
          onClick={handleOpen}
          startIcon={<Icon className="material-symbols-outlined">settings</Icon>}
          data-testid="assortment-settings-button"
        >
          {t("Settings")}
        </Button>
      </Box>
      <Slideover
        title={t("Settings")}
        open={open}
        onClose={handleClose}
        data-testid="assortment-settings-slideover"
        PaperProps={{
          sx: { minWidth: "440px" },
        }}
      >
        <Box>
          <Box sx={{ px: 2, py: 1, height: "40px" }}>
            <Typography variant="body2" color="text.secondary">
              {t("General")}
            </Typography>
          </Box>
          <Divider />
          <Container>
            <Typography variant="body1">
              {t("Highlight products where data not validated")}
            </Typography>
            <Switch
              variant="primary"
              checked={currHighlight}
              onClick={handleHighlight}
            />
          </Container>
          <Divider />
          <Container>
            <Typography variant="body1" sx={{ flex: 3 }}>
              {t("Price steps")}
            </Typography>
            <TextField
              size="small"
              value={currStep}
              placeholder={STEP_PRICE}
              onChange={handleStepChange}
              min="0"
              step="0.5"
              type="number"
              sx={{
                flex: 1,
                minWidth: "80px",
                textAlign: "right",
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: { min: 0, step: 0.5 },
                endAdornment: (
                  <InputAdornment position="end">{EUR_SYMBOL}</InputAdornment>
                ),
                sx: {
                  pr: 1,
                  "&.MuiOutlinedInput-root": {
                    "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                      borderColor: "rgba(0, 0, 0, 0.48)",
                      borderWidth: "1px",
                    },
                  },
                },
              }}
            />
          </Container>
          <Divider />
          {/* <Container>
            <Typography variant="body2" sx={{ flex: 3 }}>
              {t("Set product inactivity threshold")}
            </Typography>
            <TextField
              size="small"
              value={currThreshold}
              placeholder={DEFAULT_THRESHOLD}
              onChange={handleThresholdChange}
              min="1"
              max="30"
              step="1"
              type="number"
              sx={{
                flex: 1,
                minWidth: "80px",
                textAlign: "right",
              }}
              InputLabelProps={{
                shrink: true,
              }}
              InputProps={{
                inputProps: { min: 1, max: 30, step: 1 },
                endAdornment: (
                  <InputAdornment position="end">
                    {t(currThreshold > 1 ? "days" : "day")}
                  </InputAdornment>
                ),
                sx: { pr: 1 },
              }}
            />
          </Container>
          <Divider /> */}
        </Box>
      </Slideover>
    </>
  )
}

AssortmentAnalysisSettings.propTypes = {
  variant: PropTypes.oneOf(["contained", "outlined", "text"]),
}
AssortmentAnalysisSettings.defaultProps = {
  variant: "outlined",
}

export { AssortmentAnalysisSettings }
