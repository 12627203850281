/* eslint-disable react/forbid-prop-types */
import React, { Fragment, useCallback, useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { IconPackageOff } from "@tabler/icons-react"
import clsx from "clsx"
import html2canvas from "html2canvas"
import { Box, IconButton, CircularProgress, Tooltip, Icon } from "@mui/material"
import { useDispatch } from "react-redux"
import { useTranslation } from "react-i18next"
import { useSnackbar } from "notistack"
import ImageComp from "../../../img/Image"
import { proxyURL } from "../../../../util/env"
import * as notificationActions from "../../../../ducks/notification"

import styles from "./ArticlelImage.module.scss"

function ArticlelImage({
  alt,
  className,
  sources,
  wrapper: Wrapper,
  wrapperProps,
  controls,
}) {
  const [zoom, setZoomState] = useState(false)
  const [image, setImage] = useState("")
  const [isLoadingCopy, setLoadingCopy] = useState(false)
  const [isLoadingDownload, setLoadingDownload] = useState(false)
  const [isControlsDisabled, setDisableControls] = useState(false)
  const [backgroundPosition, setBackgroundPosition] = useState("0% 0%")
  const [rotation, setRotation] = useState(0)
  const dispatch = useDispatch()
  const { t } = useTranslation()
  const { closeSnackbar } = useSnackbar()

  useEffect(() => {
    if (image.indexOf("no-image") !== -1 || sources === null) {
      setDisableControls(true)
    }
  }, [image, sources])

  const invertHW = useMemo(() => (rotation / 90) % 2 !== 0, [rotation])

  const figureStyle = {
    backgroundPosition,
    backgroundImage: `url(${image})`,
    transform: `rotate(${rotation}deg)`,
  }

  const action = (key) => (
    <IconButton
      size="small"
      aria-label="close"
      color="inherit"
      onClick={() => {
        closeSnackbar(key)
        console.info("closeSnackbar", key)
      }}
    >
      <Icon className="material-symbols-outlined">close</Icon>
    </IconButton>
  )

  const notify = (message, error = false, icon) => {
    const key = `article-image-${Date.now()}`

    const formatedMsg = icon ? (
      <Box display="flex" alignItems="center">
        <Icon className="material-symbols-outlined" sx={{ mr: 1 }}>
          {icon}
        </Icon>
        {message}
      </Box>
    ) : (
      message
    )
    const notification = {
      message: formatedMsg,
      options: {
        key,
        action: action(key),
        autoHideDuration: 3000,
        anchorOrigin: {
          vertical: "bottom",
          horizontal: "center",
        },
        variant: "default",
      },
    }
    if (error) {
      notification.options = {
        ...notification.options,
        variant: "error",
      }
    }
    dispatch(notificationActions.enqueueNotification(notification))
  }

  const handleMouseMove = useCallback(
    (e) => {
      if (!zoom) return

      const { left, top, width, height } = e.target.getBoundingClientRect()
      let x = ((e.pageX - left) / width) * 100
      let y = ((e.pageY - top) / height) * 100
      let coords = `${x}% ${y}%`

      // fix coords for rotated images
      if (rotation === 90) {
        x = 100 - x
        coords = `${y}% ${x}%`
      } else if (rotation === 180) {
        x = 100 - x
        y = 100 - y
        coords = `${x}% ${y}%`
      } else if (rotation === 270) {
        y = 100 - y
        coords = `${y}% ${x}%`
      }

      setBackgroundPosition(coords)
    },
    [rotation, zoom],
  )

  const toggleZoom = () => setZoomState((prevZoom) => !prevZoom)

  const rotateImage = () =>
    setRotation((prevRotation) => {
      if (prevRotation === 270) {
        return 0
      }
      return prevRotation + 90
    })

  const setupImage = (callback) => {
    const img = new Image()
    img.src = image
    img.style["margin-top"] = "9999px"
    img.style.transform = `rotate(${rotation}deg)`
    img.onload = async () => {
      if (callback) {
        await callback(img)
      }
    }
  }

  const copyImage = () => {
    setLoadingCopy(true)
    const copyToClipboard = async (img) => {
      try {
        document.body.appendChild(img)
        const canvas = await html2canvas(img, {
          backgroundColor: null,
          imageTimeout: 300000,
          height: invertHW ? img.width : img.height,
          width: invertHW ? img.height : img.width,
          proxy: proxyURL,
        })
        canvas.toBlob(async (blob) => {
          try {
            await navigator.clipboard.write([new ClipboardItem({ "image/png": blob })])
            setLoadingCopy(false)
            notify("Image copied to clipboard")
          } catch (_) {
            setLoadingCopy(false)
            notify("Failed to copy image, check if the document is in focus", true)
          }
        }, "image/png")
        document.body.removeChild(img)
      } catch (_) {
        setLoadingDownload(false)
        notify("Failed to copy image", true)
      }
    }
    setupImage(copyToClipboard)
  }

  const downloadImage = () => {
    setLoadingDownload(true)
    const downloadSelectedImage = async (img) => {
      try {
        document.body.appendChild(img)
        const canvas = await html2canvas(img, {
          backgroundColor: null,
          imageTimeout: 300000,
          height: invertHW ? img.width : img.height,
          width: invertHW ? img.height : img.width,
          proxy: proxyURL,
        })

        const link = document.createElement("a")
        link.download = `download_${Date.now()}.png`
        link.href = canvas.toDataURL()
        link.click()
        document.body.removeChild(img)
        setLoadingDownload(false)
        notify("Image download started", false, "download")
      } catch (_) {
        setLoadingDownload(false)
        notify("Failed to download image", true, "download")
      }
    }
    setupImage(downloadSelectedImage)
  }

  return (
    <Box display="flex" flexDirection="column">
      <div className={clsx(styles.container, className)}>
        <Wrapper {...wrapperProps}>
          {sources === null ? (
            <IconPackageOff size={256} color="#aaa" />
          ) : (
            <Box
              className={clsx(styles["image-zoom"], zoom && styles.enabled)}
              onClick={toggleZoom}
            >
              <figure style={figureStyle} onMouseMove={handleMouseMove}>
                <ImageComp
                  className={styles.image}
                  alt={alt}
                  src={sources}
                  onSrcSelected={setImage}
                />
              </figure>
            </Box>
          )}
        </Wrapper>
      </div>
      {controls && (
        <Box alignItems="center" display="flex" gap={1} justifyContent="center">
          <Tooltip title={t("Turn Zoom on/off")} placement="bottom">
            <IconButton
              color="primary"
              size="small"
              onClick={toggleZoom}
              disabled={isControlsDisabled}
            >
              {!zoom ? (
                <Icon class="material-symbols-outlined">add_circle</Icon>
              ) : (
                <Icon class="material-symbols-outlined">do_not_disturb_on</Icon>
              )}
            </IconButton>
          </Tooltip>
          <Tooltip title={t("Rotate image 90 degrees clockwise")} placement="bottom">
            <IconButton
              color="primary"
              size="small"
              onClick={rotateImage}
              disabled={isControlsDisabled}
            >
              <Icon class="material-symbols-outlined">crop_rotate</Icon>
            </IconButton>
          </Tooltip>
          <Tooltip title={t("Copy image to clipboard")} placement="bottom">
            {isLoadingCopy ? (
              <CircularProgress color="primary" size={24} />
            ) : (
              <IconButton
                color="primary"
                size="small"
                onClick={copyImage}
                disabled={isControlsDisabled}
              >
                <Icon class="material-symbols-outlined">content_copy</Icon>
              </IconButton>
            )}
          </Tooltip>
          <Tooltip title={t("Download image")} placement="bottom">
            {isLoadingDownload ? (
              <CircularProgress color="primary" size={24} />
            ) : (
              <IconButton
                color="primary"
                size="small"
                onClick={downloadImage}
                disabled={isControlsDisabled}
              >
                <Icon class="material-symbols-outlined">download</Icon>
              </IconButton>
            )}
          </Tooltip>
        </Box>
      )}
    </Box>
  )
}

ArticlelImage.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  wrapper: PropTypes.symbol,
  wrapperProps: PropTypes.object,
  sources: PropTypes.arrayOf(PropTypes.string),
  controls: PropTypes.bool,
}

ArticlelImage.defaultProps = {
  alt: undefined,
  className: "",
  sources: null,
  wrapper: Fragment,
  wrapperProps: {},
  controls: false,
}

export default ArticlelImage
