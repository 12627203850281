import React, { useMemo } from "react"
import PropTypes from "prop-types"
import { useTranslation } from "react-i18next"
import { Button, Icon } from "@mui/material"
import { useFilterOptions, useSelectorSeletedOptions } from "./hooks"

export const EMPTY_FILTERS = {}

function AssortmentAnalysisFiltersReset({ onToggleControl }) {
  const { t } = useTranslation()
  const { selectedFilters } = useSelectorSeletedOptions()
  const { setFilterOptions } = useFilterOptions()

  const selected = useMemo(() => selectedFilters || EMPTY_FILTERS, [selectedFilters])
  const filtersApplied = useMemo(
    () => Object.values(selected).flat().length,
    [selected],
  )

  const clearFilters = () => {
    setFilterOptions(EMPTY_FILTERS)
  }

  if (onToggleControl) {
    onToggleControl(filtersApplied > 0)
  }

  if (filtersApplied === 0) {
    return null
  }

  return (
    <Button
      variant="text"
      size="small"
      data-testid="clear-button"
      onClick={clearFilters}
      startIcon={<Icon class="material-symbols-outlined">close</Icon>}
    >
      {t("Reset filters")}
    </Button>
  )
}

AssortmentAnalysisFiltersReset.propTypes = {
  onToggleControl: PropTypes.func,
}

AssortmentAnalysisFiltersReset.defaultProps = {
  onToggleControl: null,
}

export { AssortmentAnalysisFiltersReset }
