import React, { useEffect, useState } from "react"
import PropTypes from "prop-types"
import { Icon, IconButton, Snackbar } from "@mui/material"

function DXSnackbar({ autoHideDuration, children, open }) {
  const [isOpen, setIsOpen] = useState(false)

  useEffect(() => {
    if (open) {
      setIsOpen((prev) => (prev === open ? prev : open))
    }
  }, [open])

  function handleClose(e) {
    e?.preventDefault()

    setIsOpen(false)
  }

  const action = (
    <IconButton size="small" aria-label="close" color="inherit" onClick={handleClose}>
      <Icon className="material-symbols-outlined">close</Icon>
    </IconButton>
  )

  return (
    <Snackbar
      action={action}
      anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      autoHideDuration={autoHideDuration}
      onClose={handleClose}
      open={isOpen}
      message={children}
    />
  )
}

DXSnackbar.propTypes = {
  autoHideDuration: PropTypes.number,
  children: PropTypes.node,
  open: PropTypes.bool,
}

DXSnackbar.defaultProps = {
  autoHideDuration: 5000,
  children: null,
  open: false,
}

export { DXSnackbar }
