import _PropTypes from "prop-types"
import React from "react"
import ReactPlaceholder from "react-placeholder"
import { useTranslation } from "react-i18next"
import { useDispatch } from "react-redux"
import mixpanel from "mixpanel-browser"
import { useLocation } from "react-router-dom"
import { replace } from "connected-react-router"
import { MenuItem, Select } from "@mui/material"
import ExpandMoreRoundedIcon from "@mui/icons-material/ExpandMoreRounded"
import { useProductGroupFilter, useProductGroupsOutline, useSetMode } from "./hooks"

import { buildAnalyticsEventName } from "../../util/user-monitoring"
import { assortmentToolRoutes } from "../../routes/assortment-tool/AssortmentTool"
import { assortmentToolRoutes as assortmentToolHomeRoutes } from "../../routes/assortment-tool/AssortmentToolHome"
import { DATA_MODE } from "../../ducks/pages/assortment-analysis/constants"

const analyticsEventName = buildAnalyticsEventName({
  feature: "Assortment Analysis",
  target: "Product Group",
  userAction: "Selected",
})

const autocompletePlaceholder = (
  <ReactPlaceholder
    className="rounded"
    showLoadingAnimation
    style={{ width: 256, height: 32, margin: "0" }}
    type="rect"
  />
)

export function AssortmentAnalysisProductSegmentAutocomplete() {
  const { t } = useTranslation()
  const { pathname } = useLocation()
  const dispatch = useDispatch()
  const { mode } = useSetMode()
  const dataMode = mode === DATA_MODE

  const [productGroupId, setProductGroupId, isAll] = useProductGroupFilter()

  const { data: productGroups, isIdle, isFetching, isError } = useProductGroupsOutline()

  if (isIdle || isFetching) {
    return autocompletePlaceholder
  }

  if (isError) {
    return null
  }

  let categoryId = 0
  if (pathname.startsWith(assortmentToolRoutes.analysis)) {
    categoryId = Number(pathname.split("/").pop())
  }

  if (categoryId === 0) {
    return dispatch(replace(assortmentToolHomeRoutes.categorySelector))
  }

  const all = {
    id: categoryId,
    displayValue: t("All"),
    value: "all",
  }

  let productGroup =
    productGroups.find((opt) => opt.id === productGroupId) || productGroups[0]

  if (!productGroups || productGroups.length === 0 || !productGroup.id) {
    return (
      <Select
        fullWidth
        IconComponent={ExpandMoreRoundedIcon}
        size="small"
        sx={{
          "&.MuiOutlinedInput-root": {
            "& fieldset": {
              border: 0,
              borderColor: "transparent",
              boxShadow: "none",
            },
            "&:hover fieldset": {
              border: 0,
              borderColor: "transparent",
              boxShadow: "none",
            },
            "&.Mui-focused fieldset": {
              border: 0,
              borderColor: "transparent",
              boxShadow: "none",
            },
          },
          ".MuiOutlinedInput-notchedOutline": {
            border: 0,
            borderColor: "transparent",
            boxShadow: "none",
          },
          boxShadow: "none",
          minWidth: 264,
        }}
        disabled
      />
    )
  }

  const isProductGroupMismatch =
    productGroupId !== productGroup.id || productGroup.categoryId !== categoryId
  const isCategoryMismatch =
    productGroupId !== categoryId && productGroup.categoryId !== categoryId

  if ((!dataMode && isProductGroupMismatch) || (dataMode && isCategoryMismatch)) {
    productGroup = productGroups.find((opt) => opt.categoryId === categoryId)
    if (productGroup) {
      setProductGroupId(
        { id: productGroup.id },
        dataMode && productGroup.categoryId === categoryId,
      )
    }
  }

  if (!productGroup) {
    ;[productGroup] = productGroups
  }

  let productGroupChoices = productGroups
    .filter((product) => product.categoryId === categoryId)
    .map(({ id, groupName }) => ({
      id,
      displayValue: t(groupName),
      value: groupName,
    }))

  if (dataMode) {
    productGroupChoices = productGroupChoices.slice()
    productGroupChoices.unshift(all)
    if (isAll && productGroupId === categoryId) {
      productGroup = all
    }
  }

  const handleOptionSelection = ({ target }) => {
    const product = productGroupChoices.find((p) => p.id === target.value)
    mixpanel.track(analyticsEventName, { productGroup: product.displayValue })
    setProductGroupId(
      product || { id: target.value },
      dataMode && target.value === categoryId,
    )
  }

  return (
    <Select
      data-testid="assortment-autocomplete-category"
      fullWidth
      IconComponent={ExpandMoreRoundedIcon}
      onChange={handleOptionSelection}
      size="small"
      sx={{
        "&.MuiOutlinedInput-root": {
          "& fieldset": {
            border: 0,
            borderColor: "transparent",
            boxShadow: "none",
          },
          "&:hover fieldset": {
            border: 0,
            borderColor: "transparent",
            boxShadow: "none",
          },
          "&.Mui-focused fieldset": {
            border: 0,
            borderColor: "transparent",
            boxShadow: "none",
          },
        },
        ".MuiOutlinedInput-notchedOutline": {
          border: 0,
          borderColor: "transparent",
          boxShadow: "none",
        },
        boxShadow: "none",
        minWidth: 264,
      }}
      value={productGroup.id}
    >
      {productGroupChoices.map((option) => (
        <MenuItem key={option.id} value={option.id}>
          {t(option.displayValue)}
        </MenuItem>
      ))}
    </Select>
  )
}

AssortmentAnalysisProductSegmentAutocomplete.propTypes = {}

AssortmentAnalysisProductSegmentAutocomplete.defaultProps = {}
