import PropTypes from "prop-types"
import React from "react"
import mixpanel from "mixpanel-browser"
import { useTranslation } from "react-i18next"
import { Box, Icon, IconButton, Typography } from "@mui/material"
import { lowerCase, sentenceCase } from "text-case"

import styles from "./AssortmentAnalysisColumnHeaderDimension.module.scss"

import {
  DATA_MODE,
  DIMENSION_BRAND_TYPE,
  DIMENSION_PRICE,
  GRID_MODE,
} from "../../../../ducks/pages/assortment-analysis/constants"
import { SORT_ASC, SORT_DESC } from "../../../../util/sorting"
import { useDimensionSort } from "../../hooks"

import { buildAnalyticsEventName } from "../../../../util/user-monitoring"

const iconContraryLookup = {
  [SORT_ASC]: <Icon class="material-symbols-outlined">north</Icon>,
  [SORT_DESC]: <Icon class="material-symbols-outlined">south</Icon>,
}

const analyticsEventName = buildAnalyticsEventName({
  feature: "Assortment analysis",
  target: "Dimension Sort Direction Switch",
  userAction: "Clicked",
})

function AssortmentAnalysisColumnHeaderDimension({
  dimensionType,
  mode,
  iconClassName,
}) {
  const { t } = useTranslation()
  const { dimensionDirection, setDimensionDirection } = useDimensionSort()

  const handleSetDimensionDirection = () => {
    mixpanel.track(analyticsEventName)
    setDimensionDirection()
  }

  if (mode === DATA_MODE) {
    return (
      <Typography color="text.secondary" fontSize={14} className={styles.dimension}>
        {t("Product group")}
      </Typography>
    )
  }

  const sortIcon = iconContraryLookup[dimensionDirection]

  return (
    <>
      <Typography color="text.secondary" fontSize={14} className={styles.dimension}>
        {t(sentenceCase(dimensionType))}
      </Typography>
      <Box className={iconClassName}>
        <IconButton
          size="small"
          title={t(`Change ${lowerCase(dimensionType)} sort direction`)}
          onClick={handleSetDimensionDirection}
        >
          {sortIcon}
        </IconButton>
      </Box>
    </>
  )
}

AssortmentAnalysisColumnHeaderDimension.propTypes = {
  dimensionType: PropTypes.oneOf([DIMENSION_BRAND_TYPE, DIMENSION_PRICE]).isRequired,
  mode: PropTypes.oneOf([DATA_MODE, GRID_MODE]).isRequired,
  iconClassName: PropTypes.string,
}

AssortmentAnalysisColumnHeaderDimension.defaultProps = {
  iconClassName: "",
}

export { AssortmentAnalysisColumnHeaderDimension }
